@import url('../styles/colors.css');

.section {
    background-color: var(--background-color);
    background-image: url('../../assets/Header/Fondo_manos_header.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    position: relative;

    display: flex;
    height: 832px;
    width: 100vw;
    flex-direction: column;
}

.elementor_widget_warp {
    width: 100%;
    height: 100%;
}


.elementor_widget_warp_2 {
    height: 25%;
}

.elementor_widget_warp_3 {
    height: 10%;
}

.img_banner {
    width: 100%;
}

.title {
    margin: 0px;
    color: var(--primary-color);
    font-size: var(--title-text-size);
    font-family: var(--font-family);
    text-transform: capitalize;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

.container_banner {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    height: 100%;
}

.container_text {
    display: flex;
    text-align: left;
    margin-left: 9%;
    width: 70%;
    flex-direction: column;
    color: white;
}

.h1 {
    margin: 0px;
    font-size: var(--h1-text-size);
    font-family: var(--font-family);
    color: #FFFFFF;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

.h2 {
    margin: 0px;
    font-family: var(--font-family);
    font-size: var(--h2-text-size);
    color: #FFFFFF;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

.img_carousel {
    width: 100%;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.active {
    opacity: 1;
}

.container_carousel {
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.box_carousel {
    background-color: 'lightgray';
    padding: 0;
    text-align: 'center';
}


/* Tableta */
@media (min-width: 461px) and (max-width: 799px) {
    .elementor_widget_warp {
        width: 100%;
        height: 75%;
    }

    .elementor_widget_warp_2 {
        height: 30%;
    }
    .section{
        height: 700px;
    }
    .title{
        font-size: 100px;
    }
    .h1{
        font-size: 60px;
    }
    .h2{
        font-size: 30px;
    }
}
@media (min-width: 1px) and (max-width: 460px) {
    .elementor_widget_warp {
        width: 100%;
        height: 75%;
    }

    .elementor_widget_warp_2 {
        height: 30%;
    }
    .container_text{
        width: 90%;
        margin-left: 6%;
    }
    .section{
        height: 650px;
    }
    .title{
        font-size: 65px;
    }
    .h1{
        font-size: 40px;
    }
    .h2{
        font-size: 20px;
    }
}