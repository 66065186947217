@import "../styles/colors.css";

.section {
    background-color: var(--background-color);
}

.box {
    background-color: 'lightgray';
    padding: 0;
    text-align: 'center';
}
.container {
    padding-top: 5%;
    padding-left: 9%;
    padding-right: 9%;
    padding-bottom: 5%;
    height: 340px;
    background-image: url('../../assets/Contact/Corazon.png');
    background-position: center;
    background-repeat: no-repeat;
}

.container_elements {
    margin-top: 3%;
    display: flex;
    flex-direction: column;
    height: 80%;
    justify-content: space-between;
}

.h3{
    font-size: 25px;
}
.p {
    margin: 1px;
}


.social_media_logo_container {
    margin: 10px;
    display: inline-block;
}
.font_fix{
    font-family:  'Teko', sans-serif;
}

@media (min-width: 1px) and (max-width: 600px) {
    .container {
    background-size: cover;
    }
}