@import "../styles/colors.css";

.section {
    background-color: var(--background-color);
}

.box {
    background-color: 'lightgray';
    padding: 0;
    text-align: 'center';
}

.element_image_background {
    background-image: url('../../assets/Testimonios/muchas_personas.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 9%;
    padding-right: 9%;
    height: 800px;

}

.container_elements {
    padding-bottom: 7%;
    height: 100%;
}

.container_carousel {
    margin-top: 4%;
    height: 90%;
}

.card {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between
}

.header_card {
    height: 10%;
}

.content_card {
    height: 30%;
}


.h3 {
    color: var(--secondary-text-color)
}

.p {
    color: var(--secondary-text-color);
    margin: 0px;
}

.image_card {
    display: flex;
    justify-content: space-evenly;
    overflow: hidden;
}

.image {
    width: 50%;
    border-radius: 4%;
}

.image_selector {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.button_selector {
    margin: 1%;
    height: 20px;
    width: 20px;
    background: var(--secondary-color);
    border-radius: 100px;
}

.button_selector:hover {
    cursor: pointer;
}

.active {
    background-color: var(--primary-color);
}





@media ((min-width: 680px) and (max-width: 1024px)) {

    .element_image_background {
        height: 540px;
    }
}

@media ((min-width: 321px) and (max-width: 679px)) {

    .image {
        width: 75%;
    }

    .content_card {
        height: auto;
    }

    .element_image_background {
        height: 520px;
    }
}

@media ((min-width: 1px) and (max-width: 320px)) {

    .element_image_background {
        height: 590px;
    }

    .image {
        width: 75%;
    }

    .content_card {
        height: auto;
    }

    .element_image_background {
        height: 520px;
    }
}