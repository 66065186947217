@import "../styles/colors.css";

.section {
    background-color: var(--background-color);
}

.box {
    background-color: 'lightgray';
    padding: 0;
    text-align: 'center';
}

.container_programs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.element_container {
    margin-left: 9%;
    margin-right: 9%;
}

.element_text_projects {
    margin-top: 5%;
}

.span_title_color {
    color: var(--nonary-text-color);
}

.container_card {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.divisor_card {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5%;
}

.card_reduced {
    display: none;
}

.card {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.card_normal {
    display: flex;
    flex-direction: row;
}

.container_content_card {
    width: 50%;
}

.image_card {
    width: 50%;
}

.h3_title_card_first {
    text-transform: capitalize;
}

.h3_title_card_second {
    color: var(--tertiary-color);
}

.content_card {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.p {
    text-align: left;
}


.image {
    border-radius: 51px;
    width: 100%;
}

.container_button_more {
    margin-top: 5%;
    margin-bottom: 5%;
    display: flex;
    justify-content: center;
}

.button_more {
    height: 60px;
    width: 40%;
    width: max-content;
    background: var(--primary-color);
    border-radius: 20px;
    display: flex;
    align-items: center;
}

.a {
    text-decoration: none;
    text-transform: uppercase;
    color: var(--quinary-text-color);
    font-family: var(--font-family);
    font-size: var(--button-text-size);
    display: flex;
    align-items: center;
    height: 100%;
    border-radius: 20px;
    margin-left: 7px;
}

.a:hover {
    color: var(--secondary-text-color);
}

@media (min-width: 727px) {
    .section {
        margin-top: 40px;
    }    
}



@media ((min-width: 1px) and (max-width: 670px)) {
    .card_reduced {
        display: block;
        display: flex;
        flex-direction: column-reverse;
    }

    .card_normal {
        display: none;
    }

    .card {
        flex-direction: column-reverse;
    }

    .divisor_card {
        flex-direction: column;
    }

    .container_content_card {
        width: 100%;
    }

    .image_card {
        width: 100%;
    }
    .button_more{
        height: 40px;
    }
}