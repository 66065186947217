/* colors.css */
:root {
  --primary-color: #043A39;
  --secondary-color: #F5F5F5;
  --tertiary-color: #E2C395;
  --quaternary-color: #0b5857;
  --background-color: #FFFFFF;
  --button-color: #000000;
  --text-color: #000000;
  --primary-text-color: #043A39;
  --secondary-text-color: #FFFFFF;
  --tertiary-text-color: #E2C395;
  --quaternary-text-color: #000000;
  --quinary-text-color: #D9DAD7;
  --senary-text-color: #F8AEA1;
  --septenary-text-color: #E2C395;
  --octonary-text-color: #BCD4CF;
  --nonary-text-color: #D0B58F;
  --test-color: red;
  --button-text-size: 28px;
  --title-text-size: 135px;
  --h1-text-size: 75px;
  --h2-text-size: 45px;
  --h3-text-size: 35px;
  --h4-text-size: 25px;
  --paragraph-text-size: 25px;
  --font-family: 'Nunito', bold
}

/* fonts.css */
@font-face {
  font-family: 'Nunito';
  src: url('../../assets/Fonts/Nunito/static/Nunito-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  src: url('../../assets/Fonts/Nunito/static/Nunito-Bold.ttf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

.title {
  margin: 0px;
  /* color: red; */
  color: var(--primary-color);
  font-size: var(--title-text-size);
  font-family: var(--font-family);
  text-transform: capitalize;
}

h1 {
  margin: 0px;
  /* color: red; */
  color: var(--primary-color);
  font-size: var(--h1-text-size);
  font-family: var(--font-family);
  text-transform: capitalize;
}

h2 {
  text-transform: capitalize;
  margin: 0px;
  color: var(--primary-color);
  font-family: var(--font-family);
  font-size: var(--h2-text-size);
}

h3 {
  text-transform: capitalize;
  margin: 0px;
  color: var(--primary-color);
  font-family: var(--font-family);
  font-size: var(--h3-text-size);
}

h4 {
  font-weight: bold;
  text-transform: capitalize;
  margin: 0px;
  color: var(--primary-color);
  font-family: var(--font-family);
  font-size: var(--h4-text-size);
}

p {
  font-size: var(--paragraph-text-size);
  font-family: var(--font-family);
  margin-bottom: 7%;
  /* color: pink; */
}

b {
  color: var(--primary-color);
}
/* Portatiles 921px-1500px */
@media (min-width: 2000px) {
  :root {
  --button-text-size: 38px;
  --title-text-size: 140px;
  --h1-text-size: 80px;
  --h2-text-size: 55px;
  --h3-text-size: 45px;
  --h4-text-size: 35px;
  --paragraph-text-size: 35px;
  }
}
/* Portatiles 921px-1500px */
@media ((min-width: 921px) and (max-width: 1500px)) {
  :root {
  --button-text-size: 23px;
  --title-text-size: 130px;
  --h1-text-size: 70px;
  --h2-text-size: 40px;
  --h3-text-size: 30px;
  --h4-text-size: 20px;
  --paragraph-text-size: 20px;
  }
}
/* Dispositivos 800px-920px */
@media ((min-width: 800px) and (max-width: 920px)) {
  :root {
  --button-text-size: 18px;
  --title-text-size: 125px;
  --h1-text-size: 65px;
  --h2-text-size: 35px;
  --h3-text-size: 25px;
  --h4-text-size: 15px;
  --paragraph-text-size: 20px;
  }
}
/* Dispositivos 800px-920px */
@media ((min-width: 601px) and (max-width: 799px)) {
  :root {
  --button-text-size: 18px;
  --title-text-size: 120px;
  --h1-text-size: 60px;
  --h2-text-size: 33px;
  --h3-text-size: 23px;
  --h4-text-size: 18px;
  --paragraph-text-size: 18px;
  }
}
@media ((min-width: 540px) and (max-width: 600px)) {
  :root {
  --button-text-size: 15px;
  --title-text-size: 115px;
  --h1-text-size: 55px;
  --h2-text-size: 25px;
  --h3-text-size: 20px;
  --h4-text-size: 15px;
  --paragraph-text-size: 18px;
  }
}
@media ((min-width: 1px) and (max-width: 539px)) {
  :root {
  --button-text-size: 13px;
  --title-text-size: 60px;
  --h1-text-size: 45px;
  --h2-text-size: 23px;
  --h3-text-size: 20px;
  --h4-text-size: 16px;
  --paragraph-text-size: 18px;
  }
}