@import "../styles/colors.css";

.section{
    background-color: var(--background-color);
    background-image: url('../../assets/logos/Rectangle_24.png');
    background-position: left bottom;
    background-repeat: no-repeat;
}

.box{
    background-color: 'lightgray';
    padding: 0;
    text-align: 'center'; 
}
.title{
    margin: 0px;
    color: var(--tertiary-color);
    font-size: var(--title-text-size);
    font-family: var(--font-family);
    text-transform: capitalize;
  }

.container_programs{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 5%;
}

.container_elements{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}
.element_img{
    margin-left: 2%;
    width: 50%;
    display: flex;
    align-items: center;

}
.img{
    width: 100%;
}
.elements_text{
    margin-left: 9%;
    width: 50%;
}
.h2{
    font-size: var(--h2-text-size);
    font-family: var(--font-family);
    color: var(--primary-color);
    margin-bottom: 0px;
}
.paragraph_section{
    text-align: left;
}
.middle_point{
    font-size: 20px;
}
.paragraph{
    font-size: var(--paragraph-text-size);
    font-family: var(--font-family);
    margin-bottom: 0px;
    margin-top: 10px;
}


@media ((min-width: 1px) and (max-width: 600px)) {
    .element_img{
        display: none;
    }
    .elements_text{
        margin-right: 9%;
        width: 100%;
    }

}