@import "../styles/colors.css";

.section {
    background-color: var(--background-color);
}

.box {
    background-color: 'lightgray';
    padding: 0;
    text-align: 'center';
}

.background {
    background-color: var(--primary-color);
}

.container {
    padding-top: 5%;
    margin-left: 9%;
    margin-right: 9%;
    padding-bottom: 3%;
}

.elements {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.description_text_container {
    width: 60%;
}

.logo_corppaz {
    height: 100px;
}

.contact_container {
    width: 35%;
}

.p_description {
    color: var(--secondary-text-color);
    text-align: left;
}

.p {
    color: var(--secondary-text-color);
    margin: 1px;
}

.social_media_logo_container {
    margin: 10px;
    display: inline-block;
}

.link_mail {
    color: var(--secondary-text-color);
    text-decoration: none;
}

.whatsapp_button_fixed {
    bottom: 20px;
    position: fixed;
    right: 20px;
    z-index: 10;
}

.whatsapp_button {
    height: 95%;
    width: 75%;
}
.whatsapp_button_a{
    height: 100px;
    width: 100px;
    display: block;
    border-radius: 50%;
    text-align: center;
    background: #25d366;
    line-height: 50px;
}

@media ((min-width: 470px) and (max-width: 800px)) {
    .elements {
        flex-direction: column;
    }
    .whatsapp_button_a{
        height: 75px;
        width: 75px;
    }
    .description_text_container {
        width: 100%;
    }

    .contact_container {
        width: 100%;
    }
}

@media ((min-width: 1px) and (max-width: 469px)) {
    .elements {
        flex-direction: column;
    }

    .whatsapp_button_a{
        height: 65px;
        width: 65px;
    }
    .description_text_container {
        width: 100%;
    }

    .contact_container {
        width: 100%;
    }

    .logo_corppaz {
        height: 50px;
    }
}