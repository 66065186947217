@import "../styles/colors.css";

.section {
    background-color: var(--background-color);
    background-image: url('../../assets/logos/Frame_77.png');
    background-position: right top;
    background-repeat: no-repeat;
}

.box {
    background-color: 'lightgray';
    padding: 0;
    text-align: 'center';
}

.container_elements {
    display: flex;
    flex-direction: column;
    margin-top: 5%;
    margin-left: 9%;
    margin-right: 9%;
}

.element_container_text {
    display: flex;
    flex-direction: column;
}

.element_mision_vision {
    display: flex;
    width: 100%;
}

.element_text {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.element_text_warp {
    height: 30px;
}

.h1 {
    color: var(--tertiary-text-color);
}

.humanitarian_assistance_text {
    color: var(--quinary-text-color);
}

.environmental_care_text {
    color: var(--senary-text-color)
}

.social_development_text {
    color: var(--septenary-text-color)
}

.community_text {
    color: var(--octonary-text-color)
}

.h2 {
    color: var(--secondary-text-color);
}

.p {
    color: var(--quaternary-text-color);
    text-align: left;
    margin-bottom: 0px;
}

.span_title {
    color: var(--primary-text-color);
}

.container_img_up {
    margin-top: 5%;
}

.container_img {
    width: 20%;
}

.image {
    width: 100%;
}

.card_container {
    display: flex;
    flex-direction: row;
    width: 48%;
}

.card_text_container {
    margin-left: 2%;
    width: 80%;
}

.card_text_h2 {
    text-align: left;
    height: 50%;
    display: flex;
    align-items: center;
}

@media (max-width:768px) {
    .p {
        margin-top: 0px;
    }
}

/* Tableta */
@media (min-width: 300px) and (max-width: 726px) {
    .element_text {
        flex-direction: column;
    }

    .card_container {
        width: 100%;
    }

    .card_text_h2 {
        height: auto;
        /* justify-content: center; */
    }

    .card_text_container {
        margin-left: 2%;
        width: 85%;
    }

    .container_img {
        width: 15%;
    }

    .element_text_warp_cards {
        height: 25px;
    }
}